@tailwind base;
@tailwind components;
@tailwind utilities;
body {
    margin: 0;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-family: 'Avenir', sans-serif;
}

footer {
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100%;
}

@font-face {
    font-family: "Stolzl Bold";
    src: local("Stolzl"), url("../../public/fonts/Stolzl\ Bold.ttf") format("truetype");
    font-style: normal;
}

@font-face {
    font-family: "Stolzl Regular";
    src: local("Stolzl"), url("../../public/fonts/Stolzl\ Regular.ttf") format("truetype");
    font-style: normal;
}

@font-face {
    font-family: "Stolzl Book";
    src: local("Stolzl"), url("../../public/fonts/Stolzl\ Book.ttf") format("truetype");
    font-style: normal;
}

@font-face {
    font-family: "Stolzl Light";
    src: local("Stolzl"), url("../../public/fonts/Stolzl\ Light.ttf") format("truetype");
    font-style: normal;
}

body {
    font-family: 'Stolzl Book', sans-serif;
}

html,
a {
    font-family: 'Stolzl Light', sans-serif;
}

li {
    font-family: 'Stolzl Light', sans-serif;
}

li:hover {
    color: #121113;
    font-weight: 700;
}

body {
    margin: 0;
    padding: 0;
    border: 0;
    outline: 0;
    background: #121113;
    overflow-x: hidden;
}

a:hover {
    color: #121113;
    font-weight: 700;
}

input,
textarea {
    border-radius: 4px;
    border: 0;
    background: rgb(241, 242, 243);
    transition: all 0.3s ease-in-out;
    outline: none;
    width: 100%;
    padding: 1rem 1.25rem;
}

input,
textarea:focus-within {
    background: none;
    box-shadow: #2e186a 0px 0px 0px 1px;
}

h1 {
    font-family: 'Stolzl Regular', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 96px;
    line-height: 2;
    color: #FFFFFF;
}

h2 {
    font-family: 'Stolzl Regular', sans-serif;
    color: #fff;
    font-size: 64px;
    line-height: 1.18;
}

h3 {
    font-family: 'Stolzl Regular', sans-serif;
    color: #fff;
    font-size: 100px;
    line-height: 1.18;
    @media only screen and (max-width: 890px) {
        font-size: 47px;
    }
    @media only screen and (max-width: 414px) {
        font-size: 32px;
    }
}

h4 {
    font-family: 'Stolzl Regular', sans-serif;
    color: #fff;
    font-size: 32px;
    line-height: 1.18;
    @media only screen and (max-width: 890px) {
        font-size: 26px;
    }
    @media only screen and (max-width: 414px) {
        font-size: 18px;
    }
}

h5,
h6 {
    font-family: 'Stolzl Book', sans-serif;
    color: #fff;
    font-size: 56px;
    line-height: 1.18;
    @media only screen and (max-width: 890px) {
        font-size: 47px;
    }
    @media only screen and (max-width: 414px) {
        font-size: 32px;
    }
}

p {
    font-family: 'Stolzl Book', sans-serif;
    color: #fff;
    font-size: 16px;
    line-height: 1.41;
}

a {
    font-family: 'Stolzl Book', sans-serif;
    text-decoration: none;
    outline: none;
    color: #fff;
}

a:hover {
    color: #fff;
}

*:focus {
    outline: none;
}

.about-block-image svg {
    text-align: center;
}